import { graphql } from "gatsby";
import { BlockMore } from "../../components";
import { PageLayout } from "../../layouts/Page";
import { CallToActionData } from "../../queries/DataTypes";
import { SectionPartnerHero } from "../../sections/PartnerHero";
import { SectionPartnerInfo } from "../../sections/PartnerInfo";

interface PageProps {
  data: {
    mdx: {
      frontmatter: {
        title: string;
        description: string;
        keywords: string;
        hero: CallToActionData & {
          image: string;
        };
        more?: {
          heading?: string;
          content?: string;
          items: Array<
            CallToActionData & {
              image: string;
            }
          >;
        };
        info?: CallToActionData & {
          footnote?: string;
        };
        quoteRefs?: Array<string>;
      };
    };
  };
}

export default ({
  data: {
    mdx: {
      frontmatter: { title, description, keywords, hero, more, info, quoteRefs },
    },
  },
}: PageProps) => (
  <PageLayout title={title} description={description} keywords={keywords} footerCtaVisible={false}>
    <SectionPartnerHero {...hero} />
    <main>
      {more && <BlockMore {...more} />}
      {info && <SectionPartnerInfo {...info} quoteRefs={quoteRefs} />}
    </main>
  </PageLayout>
);

export const query = graphql`
  query PartnerNutanixQuery {
    mdx(fileAbsolutePath: { glob: "**/pages/partners/nutanix.md" }) {
      frontmatter {
        title
        description
        keywords
        hero {
          heading
          content
          image
        }
        more {
          items {
            image
            heading
            content
          }
        }
        info {
          heading
          content
          footnote
          action {
            text
            href
          }
        }
        quoteRefs
      }
    }
  }
`;
